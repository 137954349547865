<template>
  <section>
    <div class="position-relative">
        <img class="postImg" :src="image" alt="">
        <div class="d-flex social-icons-container p-1">
            <img v-if="facebook" class="social-icon facebook" src="../../assets/images/icon/facebook.svg" alt="">
            <img v-if="instagram" class="social-icon instagram" src="../../assets/images/icon/instagram.png" alt="">
        </div>
        <div class="row position-absolute row-position p-1">
            <div class="d-flex mr-1">
                <img class="likeIcon" src="../../assets/images/icon/facebook-like.svg" alt="">
            </div>
            <div class="d-flex">
                <span class="likesNumb">{{likes}}</span>
            </div>
        </div>
    </div>
    <span>{{date}}</span>
    <br>
    <span v-if="comments">{{comments}} comentários</span>
    <span v-else>Sem comentários</span>
    <br>
    <span>{{reached}} pessoas alcançadas</span>
    <br>
    <button class="viewBtn" @click="openLink">Ver Publicação</button>
  </section>
</template>

<script>
export default {
    props:
    {
        image: String,
        likes: Number,
        date: String,
        comments: Number,
        reached: Number,
        link: String,
        facebook: Boolean,
        instagram: Boolean
    },
    methods:
    {
        openLink: function()
        {
            window.open(this.link, "_blank");
        }
    }
}
</script>

<style scoped>
.postImg{
    width: 100%;
}
.likeIcon{
    width: 30px;
    height: 50px;
}
.likesNumb{
    color: white;
}
.social-icons-container
{
    position: absolute;
    bottom: 0;
    left: 0;
}
.social-icon{
    width: 40px;
    color: white;
}
.viewBtn{
    border-radius: 10px;
    width: 100%;
    background-color: transparent;
}
.viewBtn:hover{
    color: red;
}
.row-position{
    bottom: -7px;
    right: 20px;
    align-items: center;
}
</style>