<template>
  <section>
    <div class="container-fluid container-style">
        <div class="row">
            <div class="col-12">
                <div class="card card-block card-stretch card-height">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                    <h4 class="card-title">Vistas totais</h4>
                    </div>
                </div>
                <div class="card-body w-100">
                    <ApexChart class="w-100" element="yearly-chart-02" :chartOption="chart2" />
                </div>
                </div>
            </div>
            <div class="d-flex flex-wrap">
                <div class="col-lg-6">
                    <div class="d-flex flex-wrap">   
                        <div class="col-lg-6">
                            <div class="card card-block card-stretch card-height">
                                <div class="card-body card-align-content">
                                    <div class="rounded m-auto align-items-center">
                                    <p>Total de Visitantes</p>
                                    <h2 class="text-center">1267</h2>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between mt-3">
                                        <div class="text-success mr-2">
                                            75 %
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            height="18px"
                                            width="18px"
                                            >
                                            <path
                                                fill-rule="evenodd"
                                                d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
                                                clip-rule="evenodd"
                                            ></path>
                                            </svg>
                                        </div>
                                        <p class="mb-0">Últimos 15 dias</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card card-block card-stretch card-height">
                            <div class="card-body card-align-content">
                                <div class="rounded m-auto align-items-center">
                                <p>Total de Interações</p>
                                <h2 class="text-center">189</h2>
                                </div>
                                <div
                                class="d-flex align-items-center justify-content-between mt-3"
                                >
                                <div class="text-primary mr-2">
                                    35 %
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    height="18px"
                                    width="18px"
                                    >
                                    <path
                                        fill-rule="evenodd"
                                        d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"
                                    />
                                    </svg>
                                </div>
                                <p class="mb-0">Último Mês</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card card-block card-stretch back-img">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-center align-items-center card-total-sale">
                                        <div class="border bg-warning rounded iq-icon-box">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            height="25"
                                            width="25"
                                            class="mb-1"
                                        >
                                            <path
                                            d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                                            />
                                        </svg>
                                        </div>
                                        <div
                                        class="d-flex align-items-center card-total-sale justify-content-between mt-3"
                                        >
                                        <div class="card-text">
                                            <h5 class="text-center">Visualização / Interação</h5>
                                            <h3 class="text-center">8 / 1</h3>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card card-block card-stretch back-img">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-center align-items-center flex-column card-total-sale">
                                        <div class="border bg-warning rounded iq-icon-box">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            height="25"
                                            width="25"
                                            class="mb-1"
                                        >
                                            <path
                                            d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                                            />
                                        </svg>
                                        </div>
                                        <div
                                        class="d-flex align-items-center card-total-sale justify-content-between mt-3"
                                        >
                                        <div class="card-text">
                                            <h5 class="text-center">Novos Seguidores</h5>
                                            <h3 class="text-center">3</h3>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">  
                    <div class="card card-block card-stretch card-height">
                        <div class="card-header d-flex justify-content-between">
                            <div class="header-title">
                            <h4 class="card-title">Vistas por plataforma</h4>
                            </div>
                        </div>
                        <div class="card-body">
                            <ApexChart element="like-chart-05" :chartOption="chart4" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div class="row row-style w-100">
                <div class="col-lg-3">
                    <PostTemplate class="postTemplate" image="/img/social/1.jpg" :likes="47" date="12 setembro 2022" :comments="2" :reached="659" link="https://www.instagram.com/p/CiaUaQPA3cG/" :instagram="true"/>
                </div>
                <div class="col-lg-3">
                    <PostTemplate class="postTemplate" image="/img/social/2.jpg" :likes="8" date="2 setembro 2022" :comments="0" :reached="234" link="https://www.facebook.com/wild.douro/posts/pfbid035Ykk1iNN2Qfo1v9KuUC4Ut4c3TEZXRUh71AQCphJKvMHnUJgfSgG3GPenC4dhy7xl?__cft__[0]=AZUF31VzaJ4lQPS5BhqirMk9-pLMMaIbIOtLCmHSjCmqruAHH75_2M-Bgx6CCENWcE7eVDqzB_9AiYfRuPCZxH2Sy1RmY6Tn-YoLouV7jEQnKSUBnf0p5VMwPL4jrIaAQYNEM2Z13HtpSHl1uJdyfqvIfh8mceZ9MZDW7mmB5scI-YEYJATVaW0-JLVV_hy8AK8&__tn__=%2CO%2CP-R" :instagram="true" :facebook="true"/>
                </div>
                <div class="col-lg-3">
                    <PostTemplate class="postTemplate" image="/img/social/3.jpg" :likes="309" date="27 agosto 2022" :comments="2" :reached="234" link="https://www.facebook.com/wild.douro/posts/pfbid023gCcyV4Yf3Kp84Yzw5GsEa5UrnLbRhRyR5J1BXnPbLkgmESU2bLzFVsSUFfHBKfgl?__cft__[0]=AZX6iwb5aU6iuKDlaKF6aE0uzzQeoG5Bmsu3JA3hop65OppDcRRH314HDXANxNUiVypVUnonI_M96w52bYpgc4B0eyFs34TiPs2PFE0y6tpvpRRy2f1FrwipxeXCFVWzAAuprzJTNOCYZhkNrUsU2eeDg1PXgG1tl13iaXlO_J2YVl_0ljI9hNgQuzWIy7BaB8k&__tn__=%2CO%2CP-R" :instagram="true" :facebook="true"/>
                </div>
                <div class="col-lg-3">
                    <PostTemplate class="postTemplate" image="/img/social/4.jpg" :likes="16" date="16 agosto 2022" :comments="3" :reached="429" link="https://www.instagram.com/p/ChVFjRtM34l/" :instagram="true" :facebook="true"/>
                </div>
            </div>
    </div>
  </section>
</template>

<script>
import ApexChart from "../../../../components/charts/ApexChart";
import PostTemplate from "../../../../components/manageSocials/PostTemplate";
export default {
    components: {
        ApexChart,
        PostTemplate
    },
    data(){
        return{
            chart2: {
                series: [
                {
                    name: "Annual forecast",
                    data: [60, 40, 90, 70, 50, 60, 60, 88, 90],
                }
                ],
                colors: ["#F56692", "#ff6b6b"],
                chart: {
                height: 275,
                type: "line",
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: false,
                },
                },
                dataLabels: {
                enabled: false,
                },
                stroke: {
                curve: "smooth",
                width: 3,
                },
                title: {
                text: "",
                align: "left",
                },

                grid: {
                row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0,
                },
                },
                xaxis: {
                categories: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep",],
                labels: {
                    minHeight: 22,
                    maxHeight: 22,
                },
                },
                yaxis: {
                labels: {
                    offsetY: 0,
                    minWidth: 22,
                    maxWidth: 22,

                    // formatter: function (val) {
                    // return "€" + val;
                    // },
                },
                },
                legend: {
                position: "top",
                horizontalAlign: "left",
                offsetX: -33,
                }
            },
            chart4: {
                series: [44, 55, 15, 10],
                chart: {
                height: 300,
                type: "donut",
                },
                labels: ["Instagram", "Facebook", "Youtube", "Linkedin"],
                colors: ["#F56692", "#04237D", "#F9756A", "#697A21"],
                plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270,
                },
                },
                dataLabels: {
                enabled: false,
                },

                legend: {
                position: "top",
                },
                grid: {
                padding: {
                    top: 30,
                },
                },

                responsive: [
                {
                    breakpoint: 480,
                    options: {
                    chart: {
                        height: 300,
                    },
                    },
                },
                ],
            },
        }
    }
}
</script>

<style scoped>
.back-img:before{
    display: none;
}
.back-img:after{
    display: none;
}
.card-block.card-stretch{
    align-items: center;
    justify-content: center;
}
.card-body{
    display: flex;
    align-items: center;
}
.row-style{
    background: linear-gradient(117.76deg, rgba(255, 255, 255, 0.7) -7.21%, rgba(255, 255, 255, 0.5) 118.08%);
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 3rem;
}
.container-style{
    display: grid;
    justify-items: center;
}
.card-align-content{
    flex-direction: column;
}
@media (max-width: 992px) {
 .postTemplate{
    margin-bottom: 1rem;
 }
}
</style>